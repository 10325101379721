var exports = {};

const mimicFn = (to, from) => {
  for (const prop of Reflect.ownKeys(from)) {
    Object.defineProperty(to, prop, Object.getOwnPropertyDescriptor(from, prop));
  }

  return to;
};

exports = mimicFn; // TODO: Remove this for the next major release

exports.default = mimicFn;
export default exports;